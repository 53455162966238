import React, { useState, useEffect, useRef, useCallback, useMemo, memo, useContext } from "react";

import { AgGridReact } from 'ag-grid-react'; // React Grid Logic
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import "../../assets/css/ag-grid-overrides.css"; // Overrides#000000

import axios from "../../utils/axios";
import Button from "../Button/Button";

import { ProjectContext } from "../../context/ProjectContext/ProjectContext";

const SelectDataSample = memo((props) => {
    const { project, setProject, nextStep, previousStep, updateSaveStatus } = useContext(ProjectContext);
    const [delimiter, setDelimiter] = useState(project?.datasets?.[0]?.delimiter || ',');
    const [useCustomDelimiter, setUseCustomDelimiter] = useState(false);
    const [newlineCharacter, setNewlineCharacter] = useState(project?.datasets?.[0]?.newlineCharacter || '\n');
    const [dataset, setDataset] = useState(null);
    const [isDatasetLoading, setIsDatasetLoading] = useState(false);
    const isInitialMount = useRef(true);

    useEffect(() => {
        const getDatasetFromProject = async () => {
            if (isDatasetLoading) { return; }
            setDataset(null);
            setIsDatasetLoading(true);
            const datasetResponse = await axios.get(`/project/${project._id}/dataset`);
            setIsDatasetLoading(false);
            if (datasetResponse.data.dataset) {
                // Only set the dataset if the data.delmiter matches the current delimiter
                if (datasetResponse.data.delimiter === delimiter && datasetResponse.data.newlineCharacter === newlineCharacter) {
                    setDataset(datasetResponse.data.dataset);
                }
            } else {
                updateSaveStatus({ type: 'error', message: 'Error getting dataset' });
            }
        };

        getDatasetFromProject();
    }, [project]);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            setDataset(null);

            const handleChange = () => {
                let datasetObject = project?.datasets?.[0];
                datasetObject.delimiter = delimiter;
                datasetObject.newlineCharacter = newlineCharacter;

                const newProject = {
                    ...project,
                    datasets: [datasetObject],
                };
                setProject(newProject);
            };

            handleChange();
        }
    }, [delimiter, newlineCharacter]);

    const DatasetTable = () => {
        const [selectedRows, setSelectedRows] = useState(project?.datasets?.[0]?.sample || []);
        const [isSampleSaving, setIsSampleSaving] = useState(false);
        const [queuedSampleSaves, setQueuedSampleSaves] = useState([]); // [ { rowIndexes: [], timestamp: null } ]
        const gridRef = useRef(null);
        const debounceTimer = useRef(null);

        const columns = dataset.columns?.map((column) => ({
            ...column,
            checkboxSelection: params => {
                const displayedColumns = params.api.getAllDisplayedColumns();
                return displayedColumns[0] === params.column;
            },
            headerCheckboxSelection: params => {
                const displayedColumns = params.api.getAllDisplayedColumns();
                return displayedColumns[0] === params.column;
            },
        }));

        const debouncedSave = (selectedRowsIndexes) => {
            setSelectedRows(selectedRowsIndexes);
            setIsSampleSaving(true);

            updateSaveStatus({
                type: 'loading',
                message: 'Autosaving project',
            });

            if (debounceTimer.current) {
                clearTimeout(debounceTimer.current);
            }

            debounceTimer.current = setTimeout(async () => {
                const currentTime = Date.now();
                // Add the save to the queue
                setQueuedSampleSaves((prev) => {
                    const newQueue = [...prev];
                    newQueue.push({ rowIndexes: selectedRowsIndexes, timestamp: currentTime });
                    return newQueue;
                });

                try {
                    const response = await axios.post(`/project/${project._id}/dataset/sample`, {
                        sample: selectedRowsIndexes,
                    });

                    if (response.status === 200) {
                        // Remove the save from the queue using the timestamp
                        const newQueue = queuedSampleSaves.filter((save) => save.timestamp !== currentTime);
                        setQueuedSampleSaves(newQueue);

                        // Only update the save status if there are no queued up requests
                        if (queuedSampleSaves.length === 0) {
                            updateSaveStatus({ type: 'success', message: 'Project saved' });
                        }
                    }
                } catch (error) {
                    console.error('Error saving sample', error);
                    updateSaveStatus({ type: 'error', message: 'Error saving project' });
                }
                // Only set isSampleSaving to false after all queued up requests have finished
                if (queuedSampleSaves.length === 0) {
                    setIsSampleSaving(false);
                }

            }, 1500);
        };

        const onSelectionChanged = (params) => {
            if (params.source !== 'api') {
                const selectedRows = gridRef.current.api.getSelectedNodes();
                const selectedRowsIndexes = selectedRows.map((row) => row.rowIndex);
                debouncedSave(selectedRowsIndexes);
            }
        };

        const updateSelectedRows = (params) => {
            const nodesToSelect = [];
            const sample = dataset?.sample || [];
            sample.forEach((rowIndex) => {
                const rowNode = params.api.getDisplayedRowAtIndex(rowIndex);
                if (rowNode) {
                    nodesToSelect.push(rowNode);
                }
            });
            params.api.setNodesSelected({ nodes: nodesToSelect, newValue: true });
            setSelectedRows(sample);
        };

        const onFirstDataRendered = (params) => {
                updateSelectedRows(params);
        };

        const autoSizeStrategy = {
            type: 'fitCellContents'
        };

        return (
            <>
                <div className="ag-theme-quartz-dark" style={{ height: 430 }}>
                    <AgGridReact
                        ref={gridRef}
                        rowData={dataset.rows}
                        columnDefs={columns}
                        rowMultiSelectWithClick={true}
                        checkboxSelection={true}
                        rowSelection={'multiple'}
                        onSelectionChanged={onSelectionChanged}
                        onFirstDataRendered={onFirstDataRendered}
                        autoSizeStrategy={autoSizeStrategy}
                    />
                </div>

                <div className="mt-2">
                    <p className="text-sm font-normal leading-6 text-gray-900">
                        <span className="font-bold">
                            {selectedRows.length}
                        </span> {` `} rows selected for labeling
                    </p>
                </div>

                <div className="mt-4 flex justify-left gap-x-4">
                    <Button
                        type="button"
                        variant="primary"
                        loading={isSampleSaving}
                        disabled={selectedRows.length === 0 || isSampleSaving}
                        onClick={nextStep}
                    >
                        Next: Data Labeling
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={previousStep}
                        disabled={isSampleSaving}
                    >
                        Back
                    </Button>
                </div>
            </>
        );
    };

    const DatasetSkeleton = () => (
        <div className="postition-relative mx-auto">
            {[...Array(8)].map((_, index) => (
                <div key={index} className="animate-pulse flex justify-start items-center gap-x-4 py-3 mb-2" style={{ opacity: 1 - index * 0.12 }}>
                    <div className="h-6 bg-gray-300 rounded w-1/4"></div>
                    <div className="h-6 bg-gray-300 rounded w-1/4"></div>
                    <div className="h-6 bg-gray-300 rounded w-1/4"></div>
                    <div className="h-6 bg-gray-300 rounded w-1/4"></div>
                </div>
            ))}
            {/* Centered call stating "Proccessing File" */}
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <div className="flex justify-center items-center gap-x-2 bg-indigo-600 rounded-lg shadow-lg px-8 py-6">
                    <div className="text-white">
                        <p>One sec, dataset is being processed</p>
                    </div>
                </div>
            </div>
        </div>
    );

    const delimiterSelectedClasses = 'text-white bg-indigo-600';
    const delimiterNotSelectedClasses = 'text-gray-900 bg-white hover:bg-gray-100 hover:text-indigo-700 focus:z-10 focus:ring-2 focus:ring-indigo-700 focus:text-blue-700';

    return (
        <form>
            <div className="col-span-full">
                <p className="text-sm font-medium leading-6 text-gray-900">
                    What delimiters are used in your dataset?{` `}
                    <span className="text-red-500">*</span>
                </p>
                <div className="mt-2 inline-flex rounded-md shadow-sm" role="group">
                    <button
                        type="button"
                        className={`px-4 py-2 text-sm font-medium rounded-s-lg ${( delimiter === ',' && useCustomDelimiter === false ) ? delimiterSelectedClasses : delimiterNotSelectedClasses}`}
                        onClick={() => { setDelimiter(','); setUseCustomDelimiter(false); }}
                    >
                        Comma
                    </button>
                    <button
                        type="button"
                        className={`px-4 py-2 text-sm font-medium ${(delimiter === '\t' && useCustomDelimiter === false ) ? delimiterSelectedClasses : delimiterNotSelectedClasses}`}
                        onClick={() => { setDelimiter('\t'); setUseCustomDelimiter(false); }}
                    >
                        Tab
                    </button>
                    <button
                        type="button"
                        className={`px-4 py-2 text-sm font-medium ${(delimiter === '|' && useCustomDelimiter === false ) ? delimiterSelectedClasses : delimiterNotSelectedClasses}`}
                        onClick={() => { setDelimiter('|'); setUseCustomDelimiter(false); }}
                    >
                        Pipe
                    </button>
                    <button
                        type="button"
                        className={`px-4 py-2 text-sm font-medium rounded-e-lg ${useCustomDelimiter ? delimiterSelectedClasses : delimiterNotSelectedClasses}`}
                        onClick={() => setUseCustomDelimiter(true)}
                    >
                        Custom
                    </button>
                </div>

                {useCustomDelimiter && (
                    <div className="mt-2 flex rounded-md shadow-sm">
                        <input
                            id="delimiter"
                            name="delimiter"
                            type="text"
                            value={delimiter}
                            onChange={(e) => setDelimiter(e.target.value)}
                            required
                            className="flex-1 block w-full rounded-md border-gray-300 focus:border-indigo-600 focus:ring-indigo-600"
                        />
                    </div>
                )}
                <div className="mt-4">
                    <p className="text-sm font-medium leading-6 text-gray-900">
                        Choose a sample of your dataset{` `}
                        <span className="text-red-500">*</span>
                    </p>

                    <div className="mt-2 w-full text-sm text-gray-500 ">
                        {dataset ? (
                            <DatasetTable />
                        ) : (
                            <DatasetSkeleton />
                        )}
                    </div>
                </div>
            </div>
        </form>
    );
});

export default SelectDataSample;