import React, { useState, useContext, useEffect, useRef } from "react";
import Button from "../Button/Button";
import { ProjectContext } from "../../context/ProjectContext/ProjectContext";
import TextEntry from "../DataLabeling/TextEntry";
import { CheckCircleIcon } from "@heroicons/react/24/outline";

const DataLabeling = () => {
    const { nextStep, previousStep } = useContext(ProjectContext);
    const [isSaving, setIsSaving] = useState(false);

    const dataset = [
        {
            1: "action: set, field: loan type, value: mortgage, next_field: existing loan",
            2: "Got it, we are looking at a mortgage. Do you already have the loan you want to discuss?",
        },
    ];

    const [entryCompleteTest, setEntryCompleteTest] = useState(false);

    return (
        <div className="col-span-full">
            <div className="my-36">
                <TextEntry entry={dataset[0]} />
            </div>
            <div className="inline-flex">
                <button className="flex items-center justify-center px-3 h-8 text-sm font-medium text-white bg-gray-800 rounded-s hover:bg-gray-900">
                    Prev
                </button>
                <button className="flex items-center justify-center px-3 h-8 text-sm font-medium text-white bg-gray-800 border-0 border-s border-gray-700 rounded-e hover:bg-gray-900">
                    Next
                </button>

                <button
                    onClick={() => setEntryCompleteTest(!entryCompleteTest)}
                    className="ml-4 flex items-center justify-center px-2 h-8 text-sm font-medium text-white bg-gray-800 border-0 border-gray-700 rounded hover:bg-gray-900">
                    <CheckCircleIcon className={`h-5 w-5 ${entryCompleteTest ? 'text-green-500' : 'text-gray-500'}`} />
                </button>
            </div>

            <div className="mt-8 flex justify-left gap-x-4">
                <Button
                    type="button"
                    variant="primary"
                    loading={isSaving}
                    disabled={true}
                    onClick={nextStep}
                >
                    Next: Preview Augmentation
                </Button>
                <Button variant="secondary" onClick={previousStep}>
                    Back
                </Button>
            </div>
        </div>
    );
};

export default DataLabeling;
