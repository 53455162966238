import React from 'react'
import Page from '../../components/Page/Page'
import VerifiedEmailIcon from '../../assets/images/email-verified.svg';
import Button from '../../components/Button/Button';
import { useNavigate } from 'react-router-dom';

const EmailVerifiedPage = () => {
    const navigate = useNavigate();

  return (
    <Page title="Check your email">
    <div className="flex justify-center items-center h-full">
        <div className="text-center">
            <img src={VerifiedEmailIcon} alt="Check your email" className="mx-auto mb-8 w-32" />
            <h1 className="text-2xl font-bold mb-4">
                Your email has been verified
            </h1>
            <p className="text-gray-600 mb-8 mx-auto px-4">
                Please continue to the login page
            </p>
            <div className='mx-4 sm:mx-3'>
            <Button
                type="submit"
                tabIndex={7}
                className="mt-8 w-full"
                onClick={()=> navigate('/login')}
            >
                Login
            </Button>
            </div>
        </div>
    </div>
</Page>
  )
}

export default EmailVerifiedPage