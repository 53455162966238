import React, { createContext, useContext, useState, useEffect } from "react";
import axios from '../../utils/axios';

const AuthContext = createContext();
const AuthCheckContext = createContext(() => {});

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [shouldCheckAuth, setShouldCheckAuth] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (!shouldCheckAuth) return;

    /*
    * checkAuth: On page reload, check if the user is authenticated
    * @returns {Promise} - The user object if authenticated, otherwise clear auth and redirect to log in
    */
    const checkAuth = async () => {
      try {
        const response = await axios.get('/auth/verify');
        setIsLoading(false);
        setAuthInfo(response.data.user);
      } catch (err) {
        console.log('Error:', err);
        setIsLoading(false);
        clearAuth();
      }
    };
  
    checkAuth();
  }, [shouldCheckAuth]);

  const setAuthInfo = (userObj) => {
    setIsAuthenticated(true);
    setUser(userObj);
  };

  /*
  * clearAuth: Clear the user's authentication locally and on the server
  * @returns {Promise} - The user object if authenticated, otherwise clear auth and redirect to log in
  */
  const clearAuth = async () => {
    try {
      await axios.get('/auth/logout');
      setIsAuthenticated(false);
      setUser(null);
      localStorage.removeItem('accesstoken');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, setAuthInfo, clearAuth, isLoading, user }}>
      <AuthCheckContext.Provider value={setShouldCheckAuth}>
        {children}
      </AuthCheckContext.Provider>
    </AuthContext.Provider>
  );
};

export { AuthProvider, useAuth, AuthCheckContext };
