import { forwardRef, useImperativeHandle, useState } from 'react';
import Spinner from "../../components/Spinner/Spinner";
import { ExclamationCircleIcon, CheckCircleIcon } from '@heroicons/react/24/outline'

const SavingStatus = forwardRef((props, ref) => {
    const [status, setStatus] = useState({ type: 'loading', message: 'Loading project' });

    useImperativeHandle(ref, () => ({
        updateStatus: (newStatus) => {
            setStatus(newStatus);
        },
    }));

    const { type, message } = status;

    if (type === 'error') {
        return (
            <div className="text-red-500 flex items-center">
                <ExclamationCircleIcon className="mr-2 h-5 w-5" />
                {message}
            </div>
        );
    } else if (type === 'loading') {
        return (
            <div className="text-gray-500 flex items-center">
                <Spinner sizeClasses="h-5 w-5" />
                <span className="ml-2">{message}</span>
            </div>
        );
    } else if (type === 'success') {
        return (
            <div className="text-gray-500 flex items-center">
                <CheckCircleIcon className="mr-2 h-5 w-5 text-green-600" />
                {message}
            </div>
        );
    } else {
        return (
            <div className="text-gray-500">
                {message}
            </div>
        )
    }
});

export default SavingStatus;
