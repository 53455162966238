import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './pages/LoginPage/LoginPage';
import RegisterPage from './pages/RegisterPage/RegisterPage';
import ProjectsPage from './pages/ProjectsPage/ProjectsPage';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import { AuthProvider } from "./context/AuthContext/AuthContext";
import { ToastProvider } from './context/ToastContext/ToastContext';
import PrivateWrapper from "./components/PrivateWrapper/PrivateWrapper";
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import EditProjectPage from './pages/EditProjectPage/EditProjectPage';
import { ProjectProvider } from './context/ProjectContext/ProjectContext';
import NewProjectModal from './components/NewProjectModal/NewProjectModal';
import PublicWrapper from './components/PublicWrapper/PublicWrapper';
import HostRegisterPage from './pages/HostRegisterPage/HostRegisterPage';
import EmailVerifyPage from './pages/EmailVerifyPage/EmailVerifyPage';
import { Navigate } from 'react-router-dom';
import EmailVerifiedPage from './pages/EmailVerifiedPage/EmailVerifiedPage';

const App = () => {
  return (
    <AuthProvider>
      <ToastProvider>
        <Router>
          <Routes>
            <Route path="/login" element={<PublicWrapper><LoginPage /></PublicWrapper>} />
            <Route path="/register" element={<PublicWrapper><RegisterPage /></PublicWrapper>} />
            <Route path="/host" element={<Navigate replace to="/host/register" />} /> {/* Temp redirect */}
            <Route path="/host/register" element={<PublicWrapper><HostRegisterPage /></PublicWrapper>} />
            <Route path="/email/verified" element={<EmailVerifiedPage />} />
            <Route path="/email/verify/:userId" element={<EmailVerifyPage />} />
            <Route path="/profile" element={<PrivateWrapper><ProfilePage /></PrivateWrapper>} />
            <Route path="/test-modal" element={<NewProjectModal />} />
            <Route path="/projects/*" element={
              <PrivateWrapper>
                <Routes>
                  <Route path="/" element={<ProjectsPage />}>
                      <Route path="new" element={<NewProjectModal />} />
                  </Route>
                  <Route path=":projectId/edit" element={
                    <ProjectProvider>
                      <EditProjectPage />
                    </ProjectProvider>
                  } />
                  <Route path="*" element={<NotFoundPage />} /> {/* 404 */}
                </Routes>
              </PrivateWrapper>
            } />
            <Route path="/" element={<Navigate replace to="/login" />} />
            <Route path="*" element={<NotFoundPage />} /> {/* 404 */}
          </Routes>
        </Router>
      </ToastProvider>
    </AuthProvider>
  );
}

export default App;
