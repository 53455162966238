import DashboardNav from "../../components/DashboardNav/DashboardNav";
import { useState, useEffect } from "react";
import axios from "../../utils/axios";
import Spinner from "../../components/Spinner/Spinner";
import Page from "../../components/Page/Page";

// TODO: Use this profile object to generate the form fields
const emptyProfile = {
    basicDetails: {
        firstName: "",
        lastName: "",
        businessName: "",
        email: "",
    },
    password: {
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
    },
    notifications: {
        byEmail: {
            newSignIn: false,
            profileChanges: false,
        },
        byText: {
            newSignIn: false,
            profileChanges: false,
        },
    },
};

const ProfilePage = () => {
    const [profile, setProfile] = useState(emptyProfile);
    const [lastSavedProfile, setLastSavedProfile] = useState(emptyProfile);
    const [basicDetailsEdited, setBasicDetailsEdited] = useState(false);
    const [isProfileLoading, setIsProfileLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const getProfile = async () => {
        try {
            const response = await axios.get("/profile");
            console.log("Profile Response:", response.data.profile);
            setProfile(response.data.profile);
            setLastSavedProfile(response.data.profile);
            setIsProfileLoading(false);
        } catch (err) {
            console.log("Error:", err);
        }
    };

    useEffect(() => {
        getProfile();
    }, []);

    const saveProfile = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const response = await axios.post("/profile", {
                profile: profile,
            });
            console.log("Profile Response:", response.data);
            setLastSavedProfile(profile);
            setBasicDetailsEdited(false);
        } catch (err) {
            console.log("Error:", err);
        }
        setIsLoading(false);
    };

    const handleChange = (e) => {
        // TODO: Add a fieldset for each section of the profile and use the name attribute to determine which section to update
        if (e.target.name === "firstName" || e.target.name === "lastName" || e.target.name === "businessName") {
            setBasicDetailsEdited(true);
        }

        setProfile({
            ...profile,
            basicDetails: {
                ...profile.basicDetails,
                [e.target.name]: e.target.value,
            },
        });
    };

    return (
    <Page title="Profile">
        <div className="min-h-full">
            <DashboardNav />
            <header className="sticky top-0 z-10 bg-white border-b">
                <div className="mx-auto max-w-7xl px-4 py-4 sm:px-6 lg:px-8 flex justify-between items-center">
                    <h1 className="text-2xl font-bold tracking-tight text-gray-900">
                        Your Profile
                    </h1>
                </div>
            </header>
            <main>
                <div className="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
                    <div className="max-w-3xl">
                        <form name="basicDetails" onSubmit={ saveProfile }>
                            <div className="border-b border-gray-900/10 pb-7">
                                <h2 className="text-base font-semibold leading-7 text-gray-900">
                                    Basic Details
                                </h2>

                                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                    <div className="sm:col-span-3">
                                        <label
                                            htmlFor="firstName"
                                            className="block text-sm font-medium leading-6 text-gray-900"
                                        >
                                            First name
                                        </label>
                                        <div className="mt-2">
                                            { isProfileLoading ? (
                                                <div className="w-full h-9 bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200 animate-pulse rounded"></div>
                                            ) : (
                                                <input
                                                    type="text"
                                                    name="firstName"
                                                    id="firstName"
                                                    autoComplete="given-name"
                                                    value={ profile.basicDetails.firstName }
                                                    onChange={ handleChange }
                                                    className="block w-full rounded-md border-gray-300 focus:border-indigo-600 focus:ring-indigo-600"
                                                />
                                            )}
                                        </div>
                                    </div>

                                    <div className="sm:col-span-3">
                                        <label
                                            htmlFor="lastName"
                                            className="block text-sm font-medium leading-6 text-gray-900"
                                        >
                                            Last name
                                        </label>
                                        <div className="mt-2">
                                            { isProfileLoading ? (
                                                <div className="w-full h-9 bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200 animate-pulse rounded"></div>
                                            ) : (
                                                <input
                                                    type="text"
                                                    name="lastName"
                                                    id="lastName"
                                                    autoComplete="family-name"
                                                    value={ profile.basicDetails.lastName }
                                                    onChange={ handleChange }
                                                    className="block w-full rounded-md border-gray-300 focus:border-indigo-600 focus:ring-indigo-600"
                                                />
                                            )}
                                        </div>
                                    </div>

                                    <div className="sm:col-span-6">
                                        <label
                                            htmlFor="businessName"
                                            className="block text-sm font-medium leading-6 text-gray-900"
                                        >
                                            Company name
                                        </label>
                                        <div className="mt-2">
                                            { isProfileLoading ? (
                                                <div className="w-full h-9 bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200 animate-pulse rounded"></div>
                                            ) : (
                                                <input
                                                    id="businessName"
                                                    name="businessName"
                                                    type="text"
                                                    autoComplete="businessname"
                                                    value={ profile.basicDetails.businessName }
                                                    onChange={ handleChange }
                                                    className="block w-full rounded-md border-gray-300 focus:border-indigo-600 focus:ring-indigo-600"
                                                />
                                            )}
                                        </div>
                                    </div>

                                    <div className="sm:col-span-6">
                                        <label
                                            htmlFor="email"
                                            className="block text-sm font-medium leading-6 text-gray-900"
                                        >
                                            Email address
                                        </label>
                                        <div className="mt-2">
                                            { isProfileLoading ? (
                                                <div className="w-full h-9 bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200 animate-pulse rounded"></div>
                                            ) : (
                                                <input
                                                    id="email"
                                                    name="email"
                                                    type="email"
                                                    autoComplete="email"
                                                    value={ profile.basicDetails.email }
                                                    disabled
                                                    className="bg-gray-100 opacity-50 cursor-not-allowed block w-full rounded-md border-gray-300 focus:border-indigo-600 focus:ring-indigo-600"
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-6 flex items-center justify-end gap-x-6">
                                        <button
                                            type="button"
                                            className="text-sm font-semibold leading-6 text-gray-900"
                                            onClick={() => { setProfile(lastSavedProfile); setBasicDetailsEdited(false); }}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            disabled={ !basicDetailsEdited }
                                            className={`rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 w-32 items-center flex justify-center ${!basicDetailsEdited ? 'opacity-50 cursor-not-allowed' : ''}`}
                                        >
                                            {isLoading ? (
                                                <Spinner spinnerBackgroundColor="indigo-600" spinnerColor="white" spinnerSize="7" />
                                            ) : (
                                                <span className="h-7 flex items-center">
                                                    Save changes
                                                </span>
                                            )}
                                        </button>
                                    </div>
                            </div>
                        </form>
                    </div>
                </div>
            </main>
        </div>
    </Page>
    );
};

export default ProfilePage;
