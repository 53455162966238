import React, { createContext, useState, useCallback, useRef, useEffect, useMemo } from "react";
import { useParams } from 'react-router-dom';
import axios from "../../utils/axios";
import { debounce } from 'lodash';
import Spinner from "../../components/Spinner/Spinner";
import { ExclamationCircleIcon, CheckCircleIcon } from '@heroicons/react/24/outline'

import NameProject from "../../components/ProjectSteps/NameProject";
import UploadDataset from "../../components/ProjectSteps/UploadDataset";
import SelectDataSample from "../../components/ProjectSteps/SelectDataSample";
import DataLabeling from "../../components/ProjectSteps/DataLabeling";
import PreviewAugmentation from "../../components/ProjectSteps/PreviewAugmentation";

// Steps helpers
const initialSteps = [
    {
        id: "PROJECT_NAME",
        title: "Name Your Project",
        description:
            "Give your project a name and description to help you identify it later",
        state: "active",
        component: (props) => <NameProject {...props} />,
    },
    {
        id: "DATASET_UPLOAD",
        title: "Upload Your Dataset",
        description:
            "Select and upload the dataset you intend to use for your project",
        state: "incomplete",
        component: (props) => <UploadDataset {...props} />,
    },
    {
        id: "DATASET_SAMPLE",
        title: "Select Data Sample",
        description:
            "Choose a representative subset of your dataset for labeling and augmentation",
        state: "incomplete",
        component: (props) => <SelectDataSample {...props} />,
    },
    {
        id: "DATASET_LABEL",
        title: "Data Labeling",
        description:
            "Annotate the selected data samples with relevant labels and identify the required data augmentation techniques",
        state: "incomplete",
        component: (props) => <DataLabeling {...props} />,
    },
    {
        id: "PREVIEW_AUGMENTATION",
        title: "Preview Augmentation",
        description:
            "Examine the augmentation parameters and ensure they meet your project's requirements",
        state: "incomplete",
        component: (props) => <PreviewAugmentation {...props} />,
    },
];

const getStepStyle = (state) => {
    switch (state) {
        case "complete":
            return "bg-indigo-600 text-white ring-indigo-600";
        case "active":
            return "bg-white text-indigo-500 ring-indigo-600";
        case "incomplete":
            return "bg-white text-gray-500 ring-gray-200";
        default:
            return "bg-gray-100 text-gray-500 ring-white";
    }
};

const renderIcon = (state) => {
    if (state === "complete") {
        // Check icon for complete state
        return (
            <svg
                className="w-3.5 h-3.5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 16 12"
            >
                <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1 5.917 5.724 10.5 15 1.5"
                />
            </svg>
        );
    } else if (state === "active") {
        // Dot icon for active state
        return (
            <span className="flex items-center justify-center w-2 h-2 bg-indigo-500 rounded-full"></span>
        );
    } else {
        // Empty for incomplete state
        return "";
    }
};

const scrollToActiveStep = () => {
    const activeStep = document.querySelector("[data-active='true']");
    const headerHeight = document.querySelector("#projectPageHeader").offsetHeight;

    if (activeStep) {
        const activeStepPosition = activeStep.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = activeStepPosition - headerHeight;

        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        });
    }
};

// End steps helpers


export const ProjectContext = createContext();

export const ProjectProvider = ({ children }) => {
    const [project, setProject] = useState(null); // initial state
    const lastSavedProject = useRef(null); // initial state
    const [steps, setSteps] = useState(initialSteps);

    const saveStatus = useRef();

    const updateSaveStatus = (status) => {
        saveStatus.current.updateStatus(status);
    };

    const updateStepsBasedOnProject = (project) => {
        if (project) {
            // const newSteps = steps.map((step, index) => {
            //     // Project title and description
            //     if (index === 0) {
            //         if (project.title) {
            //             step.state = "complete";
            //         } else {
            //             step.state = "active";
            //         }
            //     }

            //     // Dataset
            //     if (index === 1) {
            //         if (project?.datasets?.length > 0) {
            //             step.state = "complete";
            //         } else if (steps[0].state === "complete") {
            //             step.state = "active";
            //         }
            //     }

            //     // Data sample
            //     if (index === 2) {
            //         if (project.dataSample) {
            //             step.state = "complete";
            //         } else if (steps[1].state === "complete") {
            //             step.state = "active";
            //         }
            //     }

            //     // Data labeling
            //     if (index === 3) {
            //         if (project.dataSample) {
            //             step.state = "complete";
            //         } else if (steps[2].state === "complete") {
            //             step.state = "active";
            //         }
            //     }

            //     // Preview augmentation
            //     if (index === 4) {
            //         if (project.dataSample) {
            //             step.state = "complete";
            //         } else if (steps[3].state === "complete") {
            //             step.state = "active";
            //         }
            //     }

            //     return step;
            // });

            // Use project.status.activeStep to determine the active step
            const newSteps = steps.map((step, index) => {
                if (step.id === project.status.activeStep) {
                    step.state = "active";
                } else if (steps.findIndex((s) => s.id === project.status.activeStep) > index) {
                    step.state = "complete";
                } else {
                    step.state = "incomplete";
                }
                return step;
            });

            setSteps(newSteps);
        }
    };

    const { projectId } = useParams();

    useEffect(() => {
        const getProject = async () => {
            try {
                const response = await axios.get(`/project/${projectId}`);
                if (response.status === 200) {
                    updateProjectState(response.data.project);
                    updateStepsBasedOnProject(response.data.project);
                    updateSaveStatus({
                        type: "success",
                        message: "Project loaded",
                    });
                } else {
                    console.error(response);
                    updateSaveStatus({
                        type: "error",
                        message: "Failed to load project",
                    });
                }
            } catch (err) {
                console.error(err);
                updateSaveStatus({
                    type: "error",
                    message: "Failed to load project",
                });
            }
        };

        getProject();
    }, []);

    // Update both project and lastSavedProject with a deep copy of the new project
    const updateProjectState = (newProject) => {
        setProject(newProject);
        lastSavedProject.current = JSON.parse(JSON.stringify(newProject)); // Deep copy
    };

    // Called everytime the project is updated
    const autoSave = async () => {
        if (project && lastSavedProject) {
            if (JSON.stringify(project) !== JSON.stringify(lastSavedProject.current)) {
                updateSaveStatus({
                    type: 'loading',
                    message: 'Autosaving project',
                });

                try {
                    const response = await axios.post('/project', project);

                    if (response.status === 201) {
                        updateSaveStatus({
                            type: 'success',
                            message: 'Project saved',
                        });

                        updateProjectState(response.data.project);
                    } else {
                        updateSaveStatus({
                            type: 'error',
                            message: 'Failed to save project',
                        });
                    }
                } catch (err) {
                    console.error(err);
                    updateSaveStatus({
                        type: 'error',
                        message: 'Failed to save project',
                    });
                }
            }
        }
    };

    // Debounce the autoSave function
    const debouncedAutoSave = debounce(() => {
        autoSave();
    }, 1500);

    // Call the debouncedAutoSave function everytime the project is updated
    useEffect(() => {
        if (project && lastSavedProject) {
            if (JSON.stringify(project) !== JSON.stringify(lastSavedProject.current)) {
                updateSaveStatus({ type: 'loading', message: 'Autosaving project' });
                debouncedAutoSave();
            }
        }

        return () => {
            debouncedAutoSave.cancel();
        };
    }, [project]);

    useEffect(() => {
        scrollToActiveStep();
    }, [steps]);

    const setActiveStep = (e, index) => {
        e.preventDefault();
        const newSteps = steps.map((step, i) => {
            if (i === index) {
                step.state = "active";
            } else if (i < index) {
                step.state = "complete";
            } else {
                step.state = "incomplete";
            }
            return step;
        });

        setSteps(newSteps);

        // Update the project status
        const newProject = { ...project };
        newProject.status.activeStep = newSteps[index].id;
        setProject(newProject);
    };

    const nextStep = (e) => {
        e.preventDefault();
        const activeIndex = steps.findIndex((step) => step.state === "active");
        if (activeIndex < steps.length - 1) {
            setActiveStep(e, activeIndex + 1);
        }
    };

    const previousStep = (e) => {
        e.preventDefault();
        const activeIndex = steps.findIndex((step) => step.state === "active");
        if (activeIndex > 0) {
            setActiveStep(e, activeIndex - 1);
        }
    };

    return (
        <ProjectContext.Provider
            value={{
                project,
                setProject,
                updateProjectState,
                saveStatus,
                updateSaveStatus,
                steps,
                getStepStyle,
                renderIcon,
                setActiveStep,
                nextStep,
                previousStep,
            }}
        >
            {children}
        </ProjectContext.Provider>
    );
};
