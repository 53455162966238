import axios from 'axios';

// Token Service for managing access token
class TokenService {
  static getToken() {
    return localStorage.getItem('accesstoken');
  }

  static setToken(token) {
    localStorage.setItem('accesstoken', token);
  }
}

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001/',
  withCredentials: true,
});

// Request interceptor for adding the access token to the request header
axiosInstance.interceptors.request.use((request) => {
  const accessToken = TokenService.getToken();
  if (accessToken) {
    request.headers['x-access-token'] = accessToken;
    // if (process.env.NODE_ENV === 'development') {
    //   console.log(`Request Token Sent to ${request.url}:`, accessToken);
    // }
  }

  // Browsers LOVE to cache shit, especially GET requests, to circumvent this, we can append a timestamp
  const url = new URL(request.url, request.baseURL);
  url.searchParams.set('_t', Date.now());
  request.url = url.toString().replace(url.origin, '');
  
  return request;
}, (error) => Promise.reject(error));

// Response interceptor for silent token refresh and redirect to login if token is expired
axiosInstance.interceptors.response.use((response) => {
  // Check and update token if present in the response
  const newToken = response.headers['x-access-token'];
  if (newToken) {
    TokenService.setToken(newToken);
    // if (process.env.NODE_ENV === 'development') {
    //   console.log(`Received a new token from ${response.config.url}:`, newToken);
    // }
  }
  return response;
}, (error) => {
  if (error.response && error.response.status === 401) {
    // Redirect to login if token is expired
    window.location.href = `/login?redirect=${window.location.pathname}`;
  }
  return Promise.reject(error);
});

export default axiosInstance;
