import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

const PublicWrapper = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const accesstoken = localStorage.getItem('accesstoken');
    if (accesstoken) {
      setIsAuthenticated(true);
    }
  }, []);

  return isAuthenticated ? (
    <Navigate to="/projects" replace />
  ) : (
    children
  );
};

export default PublicWrapper;
