import { useContext, useState, useRef } from "react";
import DashboardNav from "../../components/DashboardNav/DashboardNav";
import ProjectSteps from "../../components/ProjectSteps/ProjectSteps";
import { ProjectContext } from "../../context/ProjectContext/ProjectContext";
import SavingStatus from "../../components/SavingStatus/SavingStatus";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/outline";
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { Dialog } from "@headlessui/react";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import axios from "../../utils/axios";
import Page from "../../components/Page/Page";

const EditProjectPage = (props) => {
    const { project, updateProjectState, saveStatus, updateSaveStatus } = useContext(ProjectContext);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const projectActionDropdown = [
        { name: 'Delete project', action: () => setShowDeleteModal(true) },
    ];

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    };

    const ConfirmDeleteModal = () => {
        const projectNameRef = useRef(null);

        const navigate = useNavigate();
        const [isSaving, setIsSaving] = useState(false);

        const handleClose = () => {
            setShowDeleteModal(false);
        };

        const handleDeleteProject = () => {
            setIsSaving(true);
            axios.delete("/project/" + project._id)
                .then((response) => {
                    navigate(`/projects/`);
                })
                .catch((err) => {
                    setIsSaving(false);
                    console.error(err.message);
                });
        };

        return (
            <Page title="Edit Project">
            <Transition.Root show={showDeleteModal} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-20"
                    initialFocus={projectNameRef}
                    onClose={handleClose}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-20 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                    <div className="bg-white px-4 pb-4 pt-7 sm:p-6 sm:pb-4">
                                        <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                            <Dialog.Title
                                                as="h3"
                                                className="text-xl font-semibold leading-6 text-gray-900 flex items-center gap-x-4"
                                            >
                                                <div className="flex-none rounded-full bg-red-500/20 p-2">
                                                    <ExclamationTriangleIcon className="h-6 w-6 text-red-500" />
                                                </div>
                                                <div>Delete Project</div>
                                            </Dialog.Title>
                                            <div className="mt-7 mb-2">
                                                <p className="text-base text-gray-800">
                                                    Are you sure you want to delete this project?
                                                </p>
                                                <p className="mt-2 text-base text-gray-500">
                                                    All data will be permanently deleted and cannot be recovered.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="px-4 pt-3 pb-7 flex justify-end sm:px-6 gap-x-4 bg-white">
                                        <Button
                                            type="button"
                                            variant="secondary"
                                            onClick={() => handleClose(false)}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="danger"
                                            type="button"
                                            loading={isSaving}
                                            onClick={handleDeleteProject}
                                        >
                                            Delete
                                        </Button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </Page>
        );
    };

    const ProjectSkeleton = () => (
        <div className="animate-pulse mx-auto">
            {[...Array(5)].map((_, index) => (
                <div key={index} className="flex justify-start items-center gap-x-6 py-5 mb-4" style={{opacity: ( 1 - index * 0.2 )}}>
                    <div className="flex w-10">
                        <div className={`h-10 w-10 bg-gray-300 rounded-full`}></div>
                    </div>
                    <div className="flex w-1/2 gap-x-4">
                        <div className="min-w-96 flex-auto">
                            <div className={`h-5 bg-gray-300 rounded w-1/2`}></div>
                            <div className={`mt-3 h-4 bg-gray-300 rounded w-11/12`}></div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );

    return (
        <Page title="Edit Project">
        <div className="min-h-full bg-white">
            <DashboardNav />
            <header id="projectPageHeader" className="sticky top-0 z-10 bg-white border-b">
                <div className="mx-auto max-w-7xl px-4 py-4 sm:px-6 lg:px-8 flex justify-between items-center">
                    <h1 className="text-2xl tracking-tight text-gray-900">
                        {project ? (
                            <div className="-mt-1">
                                {project.title}
                            </div>
                        ) : (
                            <div className="w-96 h-6 bg-gray-300 rounded animate-pulse"></div>
                        )}
                         <Menu as="div" className="relative">
                            <div>
                                <Menu.Button className="ml-3 flex items-center justify-center rounded-md p-1 hover:bg-gray-100">
                                    <EllipsisHorizontalIcon className="h-6 w-6 text-gray-500" />
                                </Menu.Button>
                            </div>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="absolute left-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-0 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    {projectActionDropdown.map((item) => (
                                    <Menu.Item key={item.name}>
                                        {({ active }) => (
                                        <Menu.Button
                                            onClick={item.action}
                                            className={classNames(
                                            active ? 'bg-gray-100' : '',
                                            'block px-4 py-2 text-sm text-gray-700 w-full text-left'
                                            )}
                                        >
                                            {item.name}
                                        </Menu.Button>
                                        )}
                                    </Menu.Item>
                                    ))}
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </h1>
                    <div className="text-sm text-gray-500">
                        <SavingStatus ref={saveStatus} />
                    </div>
                </div>
            </header>
            <main>
                <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
                    {project ? (
                        <>
                            <ProjectSteps />
                            <pre className="pb-3 whitespace-pre-wrap text-sm">
                                {JSON.stringify(project, null, 2)}
                            </pre>
                        </>
                    ) : (
                        <ProjectSkeleton />
                    )}
                </div>
            </main>
            <ConfirmDeleteModal />
        </div>
    </Page>
    );
};

export default EditProjectPage;