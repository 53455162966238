import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "../../utils/axios";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

const ProjectList = () => {
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await axios.get("/project/all");
                setProjects(response.data);
                setLoading(false);
            } catch (err) {
                console.error(err.message);
            }
        };
        fetchProjects();
    }, []);

    const SkeletonLoader = () => (
        <div className="animate-pulse mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <ul role="list" className="divide-y divide-gray-100">
                {[...Array(6)].map((_, index) => (
                    <div key={index} className="flex justify-between items-center gap-x-6 py-5" style={{opacity: ( 1 - index * 0.15 )}}>
                        <div className="flex w-96 gap-x-4">
                            <div className="min-w-96 flex-auto">
                                <div className={`h-5 bg-gray-300 rounded w-1/2`}></div>
                                <div className={`mt-3 h-4 bg-gray-300 rounded w-3/4`}></div>
                            </div>
                        </div>
                        <div className="flex-auto hidden md:flex justify-end w-46">
                            <div className={`h-4 bg-gray-300 rounded w-1/4`}></div>
                        </div>
                    </div>
                ))}
            </ul>
        </div>
    );

    const StatusComponent = ({ status }) => {
        const statuseCode = status?.statusCode || 'SETTING_UP'; // Default status code
        const statuses = {
            "SETTING_UP": {
                colorClassBackground: "bg-gray-500/20",
                colorClass: "bg-gray-500",
                text: "Set up in progress",
            },
            "READY_TO_AUGMENT": {
                colorClassBackground: "bg-yellow-500/20",
                colorClass: "bg-yellow-500",
                text: "Ready to augment",
            },
            "AUGMENT_RUNNING": {
                colorClassBackground: "bg-indigo-500/20",
                colorClass: "bg-indigo-500 animate-ping",
                text: "Augment running",
            },
            "FINISHED": {
                colorClassBackground: "bg-emerald-500/20",
                colorClass: "bg-emerald-500",
                text: "Augment finished",
            },
            "ERROR": {
                colorClassBackground: "bg-red-500/20",
                colorClass: "bg-red-500",
                text: "Error",
            }
        };

        return (
            <div className="flex items-center gap-x-1.5">
                <div className={`flex-none rounded-full ${statuses[statuseCode].colorClassBackground} p-1`}>
                    <div className={`h-1.5 w-1.5 rounded-full ${statuses[statuseCode].colorClass} `} />
                </div>
                <p className="text-xs leading-5 text-gray-500">
                    {statuses[statuseCode].text}
                </p>
            </div>
        );
    }

    if (loading) {
        return <SkeletonLoader />;
    }

    return (
        <ul role="list" className="divide-y divide-gray-100">
            {projects.map((project) => (
                <li key={project._id} className="hover:bg-gray-50">
                    <Link
                        to={`/projects/${project._id}/edit`}
                        className="flex justify-between items-center gap-x-6 py-5 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"
                    >
                        <div className="flex min-w-0 gap-x-4">
                            <div className="min-w-0 flex-auto">
                                <p className="text-sm font-semibold leading-6 text-gray-900">
                                    {project.title}
                                </p>
                                <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                                    {project.description}
                                </p>
                            </div>
                        </div>
                        <div className="flex-auto flex justify-end">
                            <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                                <StatusComponent status={project.status} />
                                <p className="mt-1 text-xs leading-5 text-gray-500">
                                    Last modified{" "}
                                    <time dateTime={project.updatedAt}>
                                        {project.lastModified}
                                    </time>
                                </p>
                            </div>
                        </div>
                        <div className="flex-shrink-0 self-center flex">
                            <ChevronRightIcon className="h-5 w-5 text-gray-400" />
                        </div>
                    </Link>
                </li>
            ))}
        </ul>
    );
};

export default ProjectList;
