import Spinner from "../Spinner/Spinner";

const Button = ({ children, className, variant, size, loading, disabled, icon, ...props }) => {
    // Base classes without hover effects
    const baseClasses = "relative inline-flex items-center justify-center border text-sm leading-5 font-medium rounded-md focus:outline-none transition ease-in-out duration-150";

    // Classes for different sizes
    const normalClasses = "px-4 py-2";
    const smallClasses = "px-2 py-1";

    // Variant specific classes
    const primaryClasses = `${baseClasses} border-transparent text-white bg-black`;
    const secondaryClasses = `${baseClasses} border-gray-300 text-gray-700 bg-white`;
    const tertiaryClasses = `${baseClasses} border-transparent text-gray-700 bg-transparent`;
    const dangerClasses = `${baseClasses} border-transparent text-white bg-red-600`;

    // Classes for disabled state
    const disabledClasses = "opacity-50 cursor-not-allowed";

    // Add hover effects only if not disabled
    const primaryHover = "hover:bg-red-700";
    const secondaryHover = "hover:bg-gray-50";
    const tertiaryHover = "hover:bg-gray-50";
    const dangerHover = "hover:bg-red-500";

    let variantClasses = "";

    switch (variant) {
        case "primary":
            variantClasses = disabled ? primaryClasses : `${primaryClasses} ${primaryHover}`;
            break;
        case "secondary":
            variantClasses = disabled ? secondaryClasses : `${secondaryClasses} ${secondaryHover}`;
            break;
        case "tertiary":
            variantClasses = disabled ? tertiaryClasses : `${tertiaryClasses} ${tertiaryHover}`;
            break;
        case "danger":
            variantClasses = disabled ? dangerClasses : `${dangerClasses} ${dangerHover}`;
            break;
        default:
            variantClasses = disabled ? primaryClasses : `${primaryClasses} ${primaryHover}`;
    }

    let sizeClasses = "";
    let spinnerSize = "";

    switch (size) {
        case "normal":
            sizeClasses = normalClasses;
            spinnerSize = "w-7 h-7";
            break;
        case "small":
            sizeClasses = smallClasses;
            spinnerSize = "w-5 h-5";
            break;
        default:
            sizeClasses = normalClasses;
            spinnerSize = "w-7 h-7";
    }

    let spinnerClasses = "";

    switch (variant) {
        case "primary":
            spinnerClasses = "text-transparent fill-white";
            break;
        case "secondary":
            spinnerClasses = "text-white fill-indigo-600"
            break;
        case "tertiary":
            spinnerClasses = "text-white fill-indigo-600"
            break;
        case "danger":
            spinnerClasses = "text-transparent fill-white"
            break;
        default:
            spinnerClasses = "text-transparent fill-white"
    }

    const combinedClasses = `${variantClasses} ${disabled ? disabledClasses : ''} ${sizeClasses} ${className}`;

    return (
        <button className={`${combinedClasses} flex items-center justify-center`} disabled={disabled} {...props}>
            {icon && (
                <div className="flex-shrink-0 w-4 h-4 mr-1">
                    {!loading && icon}
                </div>
            )}
            <span className={`${loading ? 'invisible' : 'visible'} flex-grow min-w-0`}>
                {children}
            </span>
            {loading && (
                <div className="absolute">
                    <Spinner
                        customClasses={spinnerClasses}
                        sizeClasses={spinnerSize}
                    />
                </div>
            )}
        </button>
    );
};

export default Button;
