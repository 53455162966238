import Page from '../../components/Page/Page';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import CheckYourEmailIcon from '../../assets/images/check-your-email.svg';
import { useState, useEffect } from 'react';
import axios from "../../utils/axios";


const SingleDigitInput = ({ onChange, onFocus, value, index }) => {
    return (
        <input
            type="text"
            maxLength="1"
            value={value}
            onChange={(e) => onChange(e, index)}
            onKeyDown={(e) => onFocus(e, index)}
            className="w-10 h-12 sm:w-16 sm:h-16 mx-2 sm:mx-3 text-center border border-gray-300 rounded-md focus:border-red-700 focus:ring-red-700"
            autoFocus={index === 0}
        />
    );
};

const VerifyCodeComponent = () => {
    const [code, setCode] = useState(Array(6).fill(''));
    const { userId } = useParams()
    const [VerifyError, setVerifyError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleVerifyError = (err) => {
        console.error(err);
        setVerifyError(err);
    };

    useEffect(() => {
        const handlePaste = (e) => {
            e.preventDefault();
            const paste = e.clipboardData.getData('text').slice(0, 6);
            // Update the code state with the pasted data, spread into the array
            setCode(paste.split('').concat(Array(6 - paste.length).fill('')));
        };

        window.addEventListener('paste', handlePaste);

        return () => {
            window.removeEventListener('paste', handlePaste);
        };
    }, []);

    const handleChange = (e, index) => {
        const newCode = [...code];
        newCode[index] = e.target.value;
        setCode(newCode);

        // Automatically move to next input if next input exists and value was entered
        if (e.target.value && index < 5) {
            document.querySelectorAll('input[type="text"]')[index + 1].focus();
        }
    };

    const handleFocus = (e, index) => {
        if (e.key === 'Backspace' && !code[index] && index > 0) {
            // Focus the previous input if current is empty and backspace was pressed
            document.querySelectorAll('input[type="text"]')[index - 1].focus();
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        let verifyCode = '';
        code.forEach(number => {
            verifyCode += number
        });
        const delayPromise = new Promise(resolve => setTimeout(resolve, 1000));
        const request = axios.post('/auth/email/verify',{
            code:verifyCode,
            userId:userId
        })
        try {
            const response = await request;

            if (response.status === 200) {
                await delayPromise; // If the request finishes before the delay, wait for the delay
                setIsLoading(false)
                navigate(response.data.redirect);
            } else {
                console.log(response.data.msg)
                await delayPromise; // If the request finishes before the delay, wait for the delay
                setVerifyError(response.data.msg);
                setIsLoading(false); // stop loading
            }
        } catch (err) {
            console.error(err);
            await delayPromise; // If the request finishes before the delay, wait for the delay
            handleVerifyError(err.response.data.msg);
            setIsLoading(false); // stop loading
        }
    }

    return (
        <div>
            {code.map((digit, index) => (
                <SingleDigitInput
                    key={index}
                    value={digit}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    index={index}
                />
            ))}
            <div className='mt-4 mx-4 sm:mx-3'>
            {VerifyError && (
                <div className="text-red-500 text-sm font-semibold text-center">
                    {VerifyError}
                </div>
            )}
            <Button
                type="submit"
                tabIndex={7}
                className="mt-8 w-full"
                loading={isLoading}
                onClick={handleSubmit}
            >
                Continue
            </Button>
            </div>
        </div>

    );
};

const CheckYourEmailPage = () => {
    const { userId } = useParams()

    const resendEmail = async () =>{
        const delayPromise = new Promise(resolve => setTimeout(resolve, 1000));
        const request = await axios.post('/auth/email/resend',{
            userId:userId
        })

    }
    return (
        <Page title="Check your email">
            <div className="flex justify-center items-center h-full">
                <div className="text-center">
                    <img src={CheckYourEmailIcon} alt="Check your email" className="mx-auto mb-8 w-32" />
                    <h1 className="text-2xl font-bold mb-4">
                        Verify your email address
                    </h1>
                    <p className="text-gray-600 mb-8 mx-auto px-4">
                        Please enter the 6-digit verification code that was sent to your email
                    </p>
                    <div>
                        <VerifyCodeComponent />
                    </div>
                    <p className="text-gray-600 mt-8 mx-2 sm:mx-3">
                        Didn't receive the email? <Link onClick={resendEmail} className="text-red-700 hover:underline">Resend</Link>
                    </p>
                </div>
            </div>
        </Page>
    );
};

export default CheckYourEmailPage;