import React, { useState } from "react";
import Logo from "../../assets/images/park-pro.png";
import { Link } from "react-router-dom";
import Spinner from "../../components/Spinner/Spinner";
import axios from "../../utils/axios";
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../context/AuthContext/AuthContext";
import Page from "../../components/Page/Page";
import RegisterBackgroundImage from "../../assets/images/register-background.webp";

const RegisterPage = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [businessName, setBusinessName] = useState("");
    const [registerError, setRegisterError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { setAuthInfo } = useAuth();
    const navigate = useNavigate();

    const handleRegister = async (e) => {
        e.preventDefault();

        setRegisterError(null); // clear any previous register errors
        setIsLoading(true); // start loading

        // Register logic here
        const delayPromise = new Promise(resolve => setTimeout(resolve, 1000));
        const requestPromise = axios.post('/auth/register', {
            firstName: firstName,
            lastName: lastName,
            businessName: businessName,
            email: email,
            password: password,
            confirmPassword: confirmPassword,
        });

        try {
            const response = await requestPromise;

            if (response.status === 201) {
                await delayPromise; // If the request finishes before the delay, wait for the delay
                // setAuthInfo();
                navigate(response.data.redirect);
            } else {
                setRegisterError(`Registration failed with status code ${response.status}. Please try again.`);

                await delayPromise; // If the request finishes before the delay, wait for the delay
                setIsLoading(false); // stop loading
            }
        } catch (err) {
            console.error(err);
            setRegisterError(`${err.response.data.msg || 'Registration failed. Status code: ' + err.response.status}`);
            await delayPromise; // If the request finishes before the delay, wait for the delay
            setIsLoading(false); // stop loading
        }
    };

    return (
        <Page title="Register">
            <div className="relative flex justify-center md:px-12 lg:px-0 min-h-screen">
                <div className="relative z-10 flex flex-1 flex-col bg-white px-4 py-10 shadow-2xl sm:justify-center md:flex-none md:px-28">
                    <main className="mx-auto w-full max-w-md sm:px-4 md:w-96 md:max-w-sm md:px-0">
                        <div className="flex items-center justify-center">
                            <a aria-label="Home" href="/">
                                <img
                                    src={Logo}
                                    alt="Park Pro Logo"
                                    className="h-12 w-auto sm:h-16"
                                />
                            </a>
                        </div>
                        <h2 className="mt-10 text-lg font-semibold text-gray-900">
                            Get started for free
                        </h2>
                        <p className="mt-2 text-sm text-gray-700">
                            Already registered?{" "}
                            <Link to="/login" className="font-bold text-indigo-600 hover:underline">
                                Sign in
                            </Link>{" "}
                            to your account.
                        </p>
                        <form
                            onSubmit={handleRegister}
                            className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2"
                        >
                            <div>
                                <label
                                    htmlFor="firstname"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    First name{" "}
                                    <span className="text-red-500">*</span>
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="firstname"
                                        name="firstname"
                                        type="text"
                                        autoComplete="firstname"
                                        value={firstName}
                                        onChange={(e) =>
                                            setFirstName(e.target.value)
                                        }
                                        autoFocus
                                        tabIndex={1}
                                        required
                                        className="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div>
                                <label
                                    htmlFor="lastname"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    Last name{" "}
                                    <span className="text-red-500">*</span>
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="lastname"
                                        name="lastname"
                                        type="text"
                                        autoComplete="lastname"
                                        value={lastName}
                                        onChange={(e) =>
                                            setLastName(e.target.value)
                                        }
                                        tabIndex={2}
                                        required
                                        className="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="col-span-full">
                                <label
                                    htmlFor="businessname"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    Company name
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="businessname"
                                        name="businessname"
                                        type="text"
                                        autoComplete="businessname"
                                        value={businessName}
                                        onChange={(e) => setBusinessName(e.target.value)}
                                        tabIndex={3}
                                        className="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="col-span-full">
                                <label
                                    htmlFor="email"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    Email address{" "}
                                    <span className="text-red-500">*</span>
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        autoComplete="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        tabIndex={4}
                                        required
                                        className="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="col-span-full">
                                <div className="flex items-center justify-between">
                                    <label
                                        htmlFor="password"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        Password{" "}
                                        <span className="text-red-500">*</span>
                                    </label>
                                </div>
                                <div className="mt-2">
                                    <input
                                        id="password"
                                        name="password"
                                        type="password"
                                        autoComplete="current-password"
                                        value={password}
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                        tabIndex={5}
                                        required
                                        className="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="col-span-full">
                                <div className="flex items-center justify-between">
                                    <label
                                        htmlFor="confirmpassword"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        Confirm password{" "}
                                        <span className="text-red-500">*</span>
                                    </label>
                                </div>
                                <div className="mt-2">
                                    <input
                                        id="confirmpassword"
                                        name="confirmpassword"
                                        type="password"
                                        autoComplete="current-password"
                                        value={confirmPassword}
                                        onChange={(e) =>
                                            setConfirmPassword(e.target.value)
                                        }
                                        tabIndex={6}
                                        required
                                        className="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="col-span-full">
                                {registerError && (
                                    <div className="text-red-500 text-sm font-semibold mb-2 text-center">
                                        {registerError}
                                    </div>
                                )}
                                <button
                                    type="submit"
                                    className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    disabled={isLoading} // Disable the button when isLoading is true
                                >
                                    {isLoading ? (
                                        <Spinner spinnerBackgroundColor="indigo-600" spinnerColor="white" spinnerSize="7" />
                                    ) : (
                                        <span className="h-7 flex items-center">
                                            Sign up for free
                                        </span>
                                    )}
                                </button>
                            </div>
                        </form>
                    </main>
                </div>
                <div className="hidden lg:relative lg:block lg:flex-1">
                    <img
                        src={RegisterBackgroundImage}
                        alt="Register Background"
                        className="absolute inset-0 w-full h-full object-cover"
                    />
                </div>
            </div>
        </Page>
    );
};

export default RegisterPage;
